import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'form', 'open', 'content' ]

  connect() {
    this.formTarget.classList.add('hide');
  }

  openForm() {
    this.formTarget.classList.remove('hide');
    this.openTarget.classList.add('hide');
    this.contentTarget.classList.add('hide');
  }

  hideForm() {
    this.formTarget.classList.add('hide');
    this.openTarget.classList.remove('hide');
    this.contentTarget.classList.remove('hide');
  }
}
